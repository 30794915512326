<template>
  <div>
    <!-- >> <pre>{{ localeCV }}</pre> -->
    <div class="afinar">
      <center>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1200"
          :filename="candidateId"
          :pdf-quality="4"
          :localeCV="localeCV"
          :manual-pagination="false"
          pdf-format="a4"
          :html-to-pdf-options="htmlToPdfOptions"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
        </vue-html2pdf>
      </center>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueHtml2pdf from "../components/vue-html2pdf.vue";
import { API_URL } from "@/api";
export default {
  name: "CV",
  data: () => ({
    apiUrl: API_URL,
    candidate: {},
    candidateId: undefined,
    localeCV:"",
    skipQuery: true,
    htmlToPdfOptions: {
      margin: 0,

      filename: `hehehe.pdf`,

      image: {
        type: "jpeg",
        quality: 4,
      },

      enableLinks: true,

      html2canvas: {
        scale: 5,
        useCORS: true,
      },
      // pagebreak: { mode: "avoid-all" },
      jsPDF: {
        format: "letter",
        // floatPrecision: "smart",
        // orientation: "portrait",
      },
    },
  }),
  components: { VueHtml2pdf },
  apollo: {},

  created() {
    this.localeCV = localStorage.getItem("localeCV")
    this.candidateId = this.$route.params.id;
  },
  mounted() {
    this.$refs.html2Pdf.generatePdf();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {},
};
</script>
<style scoped>
.afinar {
  max-width: 800px;
  align-content: center;
  margin: auto;
}
</style>
